import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { toast, ToastContainer } from 'react-toastify'
import { Trans } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { requestOptions } from '../../../services/API'
import CalculaResultadoQuiz from './calculaResultado'
import { ditoIdentify } from '../../../helpers/dito'
import { GlobalContext } from '../../../Context/global'

import * as S from './styles'

const QuizResultado = ({ dadosQuiz, loja }) => {
  const [dadosFormQuiz, setDadosFormQuiz] = useState({
    nome: '',
    email: '',
    genero: '',
    telefone: '',
    treino_frequencia: '',
    treino_vezes_semana: '',
    treino_distancia_media: '',
    treino_aplicativo: '',
  })
  const [aceiteTermosUso, setAceiteTermosUso] = useState(false)
  const [loading, setLoading] = useState(false)
  const [erroCampos, setErroCampos] = useState({})
  const [sucessoFormQuiz, setSucessoFormQuiz] = useState(false)

  const { language } = useContext(GlobalContext)

  function handleChangeForm(event) {
    const value = event.target.value

    setDadosFormQuiz({
      ...dadosFormQuiz,
      [event.target.name]: value,
    })
  }

  const handleTermosUso = (termos) => setAceiteTermosUso(termos)

  async function handleSubmit(e) {
    e.preventDefault()

    const dados = {
      ...dadosFormQuiz,
      aceiteTermosUso,
    }

    try {
      setLoading(true)
      const response = await fetch(
        `${process.env.GATSBY_API_URL}${language}/newsletter/quiz`,
        requestOptions(dados)
      )
      const data = await response.json()

      if (response.ok) {
        setErroCampos({})
        setSucessoFormQuiz(true)
        toast.success(data.message)
        ditoIdentify(dados)
        handleResetForm()
      } else {
        toast.error(data.errors ? undefined : data.message)
        setErroCampos(data.errors || {})
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  function handleResetForm() {
    setDadosFormQuiz({
      nome: '',
      email: '',
      telefone: '',
      treino_frequencia: '',
      treino_vezes_semana: '',
      treino_distancia_media: '',
      treino_aplicativo: '',
    })
    setAceiteTermosUso(false)
  }

  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} />
      {!sucessoFormQuiz ? (
        <CalculaResultadoQuiz dadosQuiz={dadosQuiz} loja={loja} />
      ) : null}
    </>
  )
}

QuizResultado.propTypes = {
  dadosQuiz: PropTypes.object.isRequired,
  loja: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

export default QuizResultado

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
