import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Trans, Link, useI18next } from 'gatsby-plugin-react-i18next'
import ShareSocialMedia from '../../Buttons/share'

import * as S from './styles'

const ResetarQuiz = ({ loja }) => {
  return (
    <S.ButtonsWrapper className="resete-wrapper">
      <Link className="botao-resetar" to={`/totem/${loja.slug}/quiz`} id="btn-quiz">
        <Trans>REFAZER O QUIZ</Trans>
      </Link>
    </S.ButtonsWrapper>
  )
}

ResetarQuiz.propTypes = {
  loja: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

export default ResetarQuiz

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
