import React from 'react'
import PropTypes from 'prop-types'
import { useI18next } from 'gatsby-plugin-react-i18next'
import QuizVitrine from './vitrine'
// import data from '../../data/ResultadoTipoCorrida.json'
import * as S from './styles'

const QuizResultadoTipoCorrida = ({ pontuacaoTotalCorrida = null, loja }) => {
  const { language } = useI18next()

  let data

  if (language === 'br') {
    data = require('../../../data/ResultadoTipoCorridaTotem.json')
  } else {
    data = require('../../../data/ResultadoTipoCorridaTotem.json')
  }

  return (
    <S.TextResultado>
      {data.map((resultado) => {
        if (resultado.range.includes(pontuacaoTotalCorrida)) {
          return (
            <div key={resultado.titulo}>
              <h2>INDICAÇÕES PARA VOCÊ:</h2>
              <QuizVitrine vitrine={resultado.vitrine} loja={loja} />
            </div>
          )
        }
      })}
    </S.TextResultado>
  )
}

QuizResultadoTipoCorrida.propTypes = {
  pontuacaoTotalCorrida: PropTypes.number,
  loja: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

export default QuizResultadoTipoCorrida
