import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import NavLink from '../../Buttons';
import { GlobalContext } from '../../../Context/global';
import * as S from './styles';
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

const QuizVitrine = ({ vitrine, loja }) => {
  const { language } = useContext(GlobalContext);
  const data = useStaticQuery(graphql`
    query {
      allProduto {
        edges {
          node {
            pais
            alternative_id
            slug
            nome
            solado
            drop
            cabedal
            entressola
            peso
            indicadoPara
            tipoPisada
            localFoto {
              childImageSharp {
                gatsbyImageData(
                  width: 160
                  height: 86
                  transformOptions: { cropFocus: CENTER }
                  layout: FIXED
                  placeholder: BLURRED
                  quality: 80
                )
              }
            }
          }
        }
      }
    }
  `);

  console.log("pegou")

  const filteredProducts = data.allProduto.edges
  .filter((item) => item.node.pais === language && vitrine.includes(item.node.slug))
  .slice(0, 2);  // Limitar a 2 produtos



  const renderFeature = (label, value) => {
    if (!value) return null;
    
    const className = String(value).replace(/\s+/g, '-').toLowerCase(); // Transformar espaços em hífens e converter para minúsculas
    
    return (
      <S.Features
        className={className}
        style={{ backgroundColor: getBackgroundColor(label), color: '#001E62' }}
      >
        <strong>
          <Trans>{label}</Trans>
        </strong>
        {value}
      </S.Features>
    );
  };
  
  // Função auxiliar para obter a cor de fundo com base no rótulo (opcional)
  const getBackgroundColor = (label) => {
    switch (label) {
      case 'Drop':
        return '#E6E5F2';
      case 'Cabedal':
        return '#DCEEE2';
      case 'Entressola':
        return '#FAF8DD';
      case 'Solado':
        return '#FFECDC';
      case 'Pisada':
        return '#E6E5F2';
      case 'Peso':
        return '#DCEEE2';
      case 'Amortecimento':
        return '#FFECDC';
      default:
        return '#FFFFFF';
    }
  };
  

  return (
    <>
      {vitrine.length > 0 && (
        <>
          <S.tabelaComparativa>
            <table className="table-spacing">
              <thead>
                <tr>
                  <th scope="col"></th>
                  {filteredProducts.map((item) => (
                    <th key={item.node.slug}>
                      <S.BoxTenisTableComparador>
                        <S.NameTenistable>{item.node.nome}</S.NameTenistable>
                        <GatsbyImage
                          image={getImage(item.node.localFoto)}
                          alt={item.node.nome}
                        />
                        <S.IndicadoParaTable>
                          <h4>Indicado para:</h4>
                          <p>{item.node.indicadoPara}</p>
                        </S.IndicadoParaTable>
                      </S.BoxTenisTableComparador>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
              {['drop', 'cabedal', 'tipoPisada', 'entressola', 'solado', 'peso'].map((feature) => (
                <tr key={feature} className={feature}>
                  <th scope="row">
                    {feature === 'tipoPisada' ? 'Tipo de Pisada' : feature.charAt(0).toUpperCase() + feature.slice(1)}
                  </th>
                  {filteredProducts.map((item) => (
                    <td key={item.node.slug} className={item.node[feature]}>
                      {item.node[feature]}
                    </td>
                  ))}
                </tr>
              ))}
                <tr>
                  <th scope="row"></th>
                  {filteredProducts.map((item) => (
                    <td key={item.node.slug}>
                      <S.LinksWrapperTabela>
                        <S.NavLink to={`/totem/${loja.slug}/quiz/oferta`} id={`btn-oferta-${loja.slug}`}>
                          <Trans>Comprar</Trans>
                        </S.NavLink>
                      </S.LinksWrapperTabela>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </S.tabelaComparativa>

          <div className="boxquizresultado">
            <Swiper
            slidesPerView={1.2}
            spaceBetween={15}
            pagination={{ clickable: true }}
            >
              {filteredProducts.map((item) => (
                <SwiperSlide key={item.node.slug}>
                  <S.Card className="active" >
                    <div>
                      <S.Modelo>{item.node.nome}</S.Modelo>
                      <S.WrapperThumb>
                        <GatsbyImage
                          image={getImage(item.node.localFoto)}
                          alt={item.node.nome}
                        />
                      </S.WrapperThumb>
                      <S.DivIndicadoPara>
                        <S.IndicadoPara>
                          <h3>
                            <Trans>Indicado para:</Trans>
                          </h3>
                          <S.IndicadoPara>
                            <p>{item.node.indicadoPara}</p>
                          </S.IndicadoPara>
                        </S.IndicadoPara>
                      </S.DivIndicadoPara>
                    </div>
                    <S.DivFeaturesWrapper>
                    <S.FeaturesWrapper>
                      {renderFeature('Drop', item.node.drop)}
                      {renderFeature('Cabedal', item.node.cabedal)}
                      {renderFeature('Entressola', item.node.entressola)}
                      {renderFeature('Solado', item.node.solado)}
                      {renderFeature('Pisada', item.node.tipoPisada)}
                      {renderFeature('Peso', item.node.peso)}
                      {renderFeature('Amortecimento', item.node.amortecimento)}
                    </S.FeaturesWrapper>

                    </S.DivFeaturesWrapper>
                    <S.LinksWrapper>
                      <S.NavLink to={`/totem/${loja.slug}/asics-back`}>
                        COMPRAR
                      </S.NavLink>
                    </S.LinksWrapper>
                  </S.Card>
                </SwiperSlide>
              ))}
             </Swiper>
          </div>
        </>
      )}
    </>
  );
};

QuizVitrine.propTypes = {
  vitrine: PropTypes.array.isRequired,
  loja: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default QuizVitrine;
