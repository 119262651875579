import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ditoTrack } from '../../../helpers/dito'
import ResetarQuiz from './resetar'
import QuizResultadoTipoCorrida from './resultadoTipoCorrida'
import data from '../../../data/quizTotem'

const CalculaResultadoQuiz = ({ dadosQuiz, loja }) => {
  const [pontuacaoTotalCorrida] = useState(
    dadosQuiz.dadosCorrida.map((item) => item.peso).reduce((a, b) => a + b, 0 - dadosQuiz.dadosCorrida[4].peso)
  )

  useEffect(() => {
    const ditoData = {
      pontuacao_total: pontuacaoTotalCorrida,
    }

    data.tipoCorrida.forEach((v, k) => {
      const alternativaSelecionada = v.alternativas.find(
        (alternativa) => alternativa.peso === dadosQuiz.dadosCorrida[k].peso
      )
      ditoData[v.slug] = alternativaSelecionada
    })

    ditoTrack('respondeu-quiz', ditoData)
  }, [])
  
  return (
    <>
      {pontuacaoTotalCorrida && (
        <QuizResultadoTipoCorrida
          pontuacaoTotalCorrida={pontuacaoTotalCorrida}
          loja={loja}
        />
      )}
      <ResetarQuiz
        shareParams={{ pontuacaoTotalCorrida }}
        loja={loja}
      />
    </>
  )
}

CalculaResultadoQuiz.propTypes = {
  dadosQuiz: PropTypes.object.isRequired,
  loja: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

export default CalculaResultadoQuiz
